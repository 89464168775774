var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-content-click":false,"location":"center","left":"","attach":"","nudge-top":100,"nudge-left":20,"offset-x":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"color":"secondary","elevation":"0","medium":"","raised":"","rounded":"","text":"","icon":""}},'v-btn',attrs,false),on),[_c('IconBase',{attrs:{"icon":"geo"}})],1)]}}])},[_c('div',{staticClass:"pa-5 bg-white"},[_c('gmap-map',{staticStyle:{"width":"300px","max-width":"100%","height":"200px"},attrs:{"center":{ lat: _vm.item.lat, lng: _vm.item.lon },"zoom":17,"options":{
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false,
				minZoom: 2,
			}}},[_c('gmap-marker',{key:_vm.index,attrs:{"position":{ lat: _vm.item.lat, lng: _vm.item.lon }}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }