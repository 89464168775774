import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import { DeleteType, DialogDeleteType } from "@/interfaces/Core/dialog";
import { isArray } from "lodash";

@Component({
	components: { DialogDefault },
	methods: {},
})
export default class DeleteDialog extends Vue {
	@Prop({ required: true }) public open: Boolean = false;
	@Prop({ default: null }) public title!: string;
	@Prop({ default: false }) public loading!: Boolean;
	@Prop({ default: null }) public message!: string;
	@Prop({ default: null }) public data!: any;
	@Prop({ default: null }) public type!: DialogDeleteType;

	@Emit("action")
	public actionRemove(event: DeleteType) {
		return { event, type: this.type, data: this.data };
	}

	public get getMessage() {
		return this.$t(`${this.message}`);
	}

	public get isDataArray() {
		return isArray(this.data);
	}

	public get hasPropertyName() {
		return (
			typeof this.data === "object" && this.data.hasOwnProperty("name")
		);
	}

	private created() {
		this.$nextTick(() => {});
	}

	private mounted() {}

	public handleCancel() {
		this.actionRemove(DeleteType.CANCEL);
	}

	public handleConfirm() {
		this.actionRemove(DeleteType.CONFIRM);
	}
}
