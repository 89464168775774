import { Component, Emit, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import DispatchMixin from "@/mixins/Dispatch";
import { SelectedPrivatePoisEntity } from "@/models/persons/v12/PrivatePois";
import Uploader from "@/views/Admin/PrivatePois/Dependencies/Uploader/index.vue";
import PrivatePoisItems from "@/views/Admin/PrivatePois/Dependencies/PrivatePoisItems/index.vue";

@Component({
	components: { Uploader, PrivatePoisItems },
	mixins: [DispatchMixin],
})
export default class CardItems extends Vue {
	/**
	 * DATA
	 */
	public uploadFile: File | null = null;

	/**
	 * GETTERS
	 */
	@Getter("privatePois/getSelectedPrivatePois")
	selectedPrivatePois!: SelectedPrivatePoisEntity;

	public get selectedFile(): File | null {
		return this.uploadFile;
	}

	public set selectedFile(val: File | null) {
		this.uploadFile = val;
	}

	public get getPaginatedItems() {
		return this.selectedPrivatePois.items;
	}

	public get getLayerId() {
		return this.selectedPrivatePois.private_pois.id;
	}

	/**
	 * METHOD
	 */
	public handleUpdate(event: any) {
		const { page } = event;

		if (page) {
			this.onSetCurrentPage(page);
		}
	}

	public handleUploaded(event: any) {
		const { file } = event;
		this.uploadFile = file;
	}

	public async handleSuccess() {
		await this.onUploadSuccess();
		this.handleUploaded({ file: null });
	}

	/**
	 * EMITTERS
	 */
	@Emit("update")
	public async onSetCurrentPage(val: number) {
		return { page: val };
	}

	@Emit("success")
	public async onUploadSuccess() {
		return { success: true };
	}
}
