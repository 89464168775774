import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import DispatchMixin from "@/mixins/Dispatch";
import {
	PrivatePoisResourceEntity,
	SelectedPrivatePoisEntity,
} from "@/models/persons/v12/PrivatePois";

import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";

@Component({
	components: { CardTextField, CardAutocomplete },
	mixins: [DispatchMixin],
})
export default class PrivatePoisForm extends Vue {
	/**
	 * DATA
	 */
	resource: PrivatePoisResourceEntity = new PrivatePoisResourceEntity();

	/**
	 * GETTERS
	 */
	@Getter("privatePois/getSelectedPrivatePois")
	selectedPrivatePois!: SelectedPrivatePoisEntity;

	public get getPrivatePois() {
		return this.selectedPrivatePois.private_pois;
	}

	public get getItems() {
		return this.selectedPrivatePois.items;
	}

	/**
	 * METHOD
	 */
	public dispatchStore!: <T>(moduleFunc: string, data: T) => Promise<any>;

	private async fetchDataCountry() {
		try {
			const countries: any[] = await this.dispatchStore(
				"privatePois/fetchCountry",
				{
					type: "countries",
				}
			);
			this.resource.setResource("country_code", countries);
		} catch (error) {}
	}

	/**
	 * Vue lifecycle hooks
	 */
	private created() {
		this.$nextTick(async () => {
			await this.fetchDataCountry();
		});
	}

	private mounted() {
		this.$nextTick(() => {});
	}

	private beforeDestroy() {}

	private destroyed() {}
}
