var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.getItems,"mobile-breakpoint":null,"item-key":"name","show-select":true,"single-select":false,"items-per-page":_vm.perPage,"footer-props":{
			showFirstLastPage: false,
			showPreviousNextPage: false,
			itemsPerPageOptions: [25],
			prevIcon: '',
			nextIcon: '',
		}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-actions',[_c('CardButton',{attrs:{"label":_vm.btnDeleteText,"outlined":false,"cardStyles":"ma-1","btnClass":"","reference":"delete_all","disabled":!_vm.selectedItems.length},on:{"click":function($event){return _vm.handleRemoveSelected(
							_vm.getDialogDeleteType.ALL,
							_vm.selectedItems
						)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-delete")])],1),(_vm.selectedItems.length)?_c('CardButton',{attrs:{"label":_vm.$t('privatepois.btn.clear_selections'),"outlined":true,"cardStyles":"ma-1","btnClass":"","reference":"clear_selections","disabled":!_vm.selectedItems.length},on:{"click":function($event){_vm.selectedItems = []}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-close")])],1):_vm._e()],1)]},proxy:true},_vm._l((_vm.headers),function(property){return {key:`header.${property.value}`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text).toString().toUpperCase())+" ")]}}}),{key:"item.actions",fn:function({ item, index }){return [_c('v-btn',{staticClass:"ma-0",attrs:{"color":"secondary","elevation":"0","medium":"","raised":"","rounded":"","text":"","icon":""},on:{"click":function($event){return _vm.handleRemoveSelected(_vm.getDialogDeleteType.SINGLE, item)}}},[_c('IconBase',{attrs:{"icon":"trash"}})],1),_c('ViewIconLatLon',{attrs:{"item":item,"index":index}})]}},{key:`footer.page-text`,fn:function({ pageStart, pageStop }){return [_vm._v(" "+_vm._s(_vm.$t("pagination.show_count", { pageStart, pageStop, total: _vm.getTotal, }))+" ")]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),(_vm.getItems.length)?_c('div',{staticClass:"text-center py-8"},[_c('v-pagination',{attrs:{"total-visible":15,"length":_vm.getLength},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"title":_vm.dialogDeleteData.title,"type":_vm.dialogDeleteData.type,"open":_vm.isDialogOpen,"loading":_vm.dialogDeleteData.loading,"message":_vm.dialogDeleteData.message,"data":_vm.dialogDeleteData.data},on:{"action":_vm.handleDeleteAction}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }