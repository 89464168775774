import { Component, Prop, Vue } from "vue-property-decorator";
import IconBase from "@/components/Commons/Icons/IconBase.vue";

@Component({
	components: { IconBase },
	mixins: [],
})
export default class ViewIconLatLon extends Vue {
	/**
	 * PROP
	 */
	@Prop({ required: true }) public item!: Record<string, any>;
	@Prop({ required: true }) public index!: number;
}
