import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CardFileInput from "@/components/Content/CardFileInput.vue";
import CardAction from "@/components/Content/CardAction.vue";
import { CardActionType } from "@/interfaces/action";
import { TypeLoading } from "@/interfaces/loading";
import DispatchMixin from "@/mixins/Dispatch";
import { FileResponseEntity } from "@/models/Upload";
import { sleep } from "@/utils/convert";
import { Getter } from "vuex-class";
import { SelectedPrivatePoisEntity } from "@/models/persons/v12/PrivatePois";

@Component({
	components: { CardFileInput, CardAction },
	mixins: [DispatchMixin],
})
export default class Uploader extends Vue {
	@Prop({ required: true }) public value!: File | null;
	
	/**
	 * GETTERS
	 */
	@Getter("privatePois/getSelectedPrivatePois")
	selectedPrivatePois!: SelectedPrivatePoisEntity;

	/**
	 * DATA
	 */
	public edit_layer: boolean = false;
	public file_response: FileResponseEntity = new FileResponseEntity();

	/**
	 * GETTERS
	 */

	public get selectedFile(): File | null {
		return this.value;
	}

	public set selectedFile(val: File | null) {
		this.onSelectedFile(val);
	}

	public get getShowActions() {
		let actions: CardActionType[] = [];

		if (this.edit_layer) {
			actions = [CardActionType.SAVE, CardActionType.CANCEL];
		} else {
			actions = [CardActionType.UPLOAD];
		}

		return actions;
	}

	public get getTextActions() {
		let texts: Record<string, string> = {
			[CardActionType.UPLOAD]: "customListItem.labels.uploadInput",
			[CardActionType.SAVE]: "edit.layer",
		};

		return texts;
	}

	/**
	 * METHOD
	 */
	public dispatchStore!: <T>(moduleFunc: string, data: T) => Promise<any>;

	private async setLoadingData(actionType?: TypeLoading) {
		await this.dispatchStore("loading/setLoadingData", actionType);
	}

	async handleClickButton() {
		this.edit_layer = !this.edit_layer;
	}

	handleResponse(event: { file: FileResponseEntity }) {
		const { file } = event;
		this.file_response = file;
	}

	private async sendReplaceUpload() {
		try {
			await this.setLoadingData(TypeLoading.loading);

			const payload: FormData = await this.createFormData();

			const payloadModule = {
				type: "replace_privatepois",
				payload,
				has_file: true,
			};

			await this.dispatchStore("privatePois/postData", payloadModule);

			await this.onUploadSuccess();

			await this.setLoadingData();
		} catch (error) {
			await this.setLoadingData();
			console.error("Uploader::formData", error);
		}
	}

	private async createFormData() {
		let formData = new FormData();
		formData.append("layer_id", String(this.selectedPrivatePois.private_pois.id));
		formData.append("country_code", String(this.selectedPrivatePois.private_pois.country_code));
		formData.append("layer_name", String(this.selectedPrivatePois.private_pois.layer_name));
		if (this.selectedFile instanceof File) {
			formData.append("file", this.selectedFile);
		}
		return formData;
	}

	public async handleAction({ type }: { type: string }): Promise<void> {
		if (type === CardActionType.CANCEL) {
			this.edit_layer = false;
			this.onSelectedFile(null);
		}

		if (type === CardActionType.UPLOAD) {
			this.edit_layer = true;
			await sleep(10);
			await this.openFileUploader();
		}

		if (type === CardActionType.SAVE) {
			await this.sendReplaceUpload();
		}
	}
	
	/**
	 * Abrir el uploader
	 * @returns 
	 */
	private async openFileUploader() {
		const component: any = this.$refs.fileRef;
		if (!component || typeof component.openUploader !== "function") return;
		component.openUploader();
	}

	/**
	 * EMITTERS
	 */
	@Emit("uploaded")
	public async onSelectedFile(val: File | null) {
		return { file: val };
	}

	@Emit("success")
	public async onUploadSuccess() {
		this.edit_layer = false;
		return { success: true };
	}
}
