import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DispatchMixin from "@/mixins/Dispatch";
import Alertize from "@/components/Alertize.vue";
import { Getter } from "vuex-class";
import { SelectedPrivatePoisEntity } from "@/models/persons/v12/PrivatePois";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import paramsService from "@/services/params-service";
import { getDefaultSortingOption } from "@/utils/filter-global";
import { TypeLoading } from "@/interfaces/loading";
import CardItems from "@/views/Admin/PrivatePois/Dependencies/CardItems/index.vue";
import PrivatePoisForm from "@/views/Admin/PrivatePois/Dependencies/PrivatePoisForm/index.vue";
import CardAction from "@/components/Content/CardAction.vue";
import { CardActionType } from "@/interfaces/action";

@Component({
	components: { Alertize, PrivatePoisForm, CardItems, CardAction },
	mixins: [DispatchMixin],
})
export default class EditPrivatePois extends Vue {
	/**
	 * GETTERS
	 */
	@Getter("privatePois/getSelectedPrivatePois")
	selectedPrivatePois!: SelectedPrivatePoisEntity;

	/**
	 * DATA
	 */
	private config: {
		paginated: Paginated;
		options: SortingOption;
		filters: Record<string, any>;
		fields: any;
	} = {
		paginated: { page: 1, limit: 25 },
		options: getDefaultSortingOption("id"),
		fields: [],
		filters: [],
	};

	/**
	 * GET
	 */
	private async getItemsConfig() {
		return await paramsService.getParams(
			this.config.paginated,
			this.config.filters,
			this.config.options,
			false,
			this.config.fields
		);
	}

	/**
	 *  METHOD
	 */
	public dispatchStore!: <T>(moduleFunc: string, data: T) => Promise<any>;
	public get getParamID() {
		return Number(this.$route.params.id);
	}

	private async setLoadingData(actionType?: TypeLoading) {
		await this.dispatchStore("loading/setLoadingData", actionType);
	}

	async fetchPrivatePoisById(id: number) {
		try {
			if (!id || isNaN(Number(id))) {
				return this.redirecTo("PrivatePoisCreate");
			}

			const config = await this.getItemsConfig();

			const params = {
				...config,
				id,
			};

			await this.setLoadingData(TypeLoading.loading);

			await this.dispatchStore(
				"privatePois/paginatedItemsPrivatePois",
				params
			);

			await this.setLoadingData();
		} catch (error) {
			await this.setLoadingData();
			console.error("fetchPrivatePoisById", error);
		}
	}

	redirecTo(name: string, params?: any) {
		this.$router.push({ name, params });
	}

	public async handleUpdate(event: any) {
		const { page } = event;

		if (page) {
			this.config.paginated.page = page;
			await this.fetchPrivatePoisById(this.getParamID);
		}
	}

	public async handleSuccess(event: any) {
		await this.handleUpdate({ page: 1 });
	}

	// Método para manejar acciones
	public async handleAction(action: { type: string }): Promise<void> {
		switch (action.type) {
			case CardActionType.BACK:
				this.redirecTo("PrivatePoisCreate");
				break;
		}
	}

	/**
	 * Vue lifecycle hooks
	 */
	private created() {
		this.$nextTick(async () => {
			await this.fetchPrivatePoisById(this.getParamID);
		});
	}

	private mounted() {
		this.$nextTick(() => {});
	}

	private beforeDestroy() {}

	private destroyed() {}
}
