import { DialogDelete, DialogDeleteType } from "@/interfaces/Core/dialog";

export class DialogDeleteEntity implements DialogDelete {
	open: Boolean = false;
	loading: Boolean = false;
	message: string = "";
	title: string = "";
	data: Record<string, any> | Record<string, any>[] = {};
    type: DialogDeleteType = DialogDeleteType.SINGLE;

	constructor(parameters?: DialogDelete) {
		this.loading = parameters?.loading || false;
		this.open = parameters?.open || false;
		this.message = parameters?.message || "";
		this.title = parameters?.title || "";
		this.data = parameters?.data || {};
		this.type = parameters?.type || DialogDeleteType.SINGLE;
	}

	setOpen(open: Boolean) {
		this.open = open;
	}

	setLoading(loading: Boolean) {
		this.loading = loading;
	}

	setMessage(message: string) {
		this.message = message;
	}

	setTitle(title: string) {
		this.title = title;
	}

	setType(type: DialogDeleteType) {
		this.type = type;
	}

	setData(data: Record<string, any> | Record<string, any>[]) {
		this.data = data;
	}
}
