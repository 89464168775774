export interface DialogDelete {
	open: Boolean;
	loading: Boolean;
	title: string;
	message: string;
	data: Record<string, any> | Record<string, any>[];
    type: DialogDeleteType;
}

export enum DeleteType {
	CANCEL = "cancel",
	CONFIRM = "confirm",
}

export enum DialogDeleteType {
    SINGLE = "single",
    ALL = "all"
}