
import Vue from "vue";
export default Vue.extend({
	name: "CardButton",
	model: {},
	props: {
		label: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		reference: {
			type: String,
			default: "",
		},
		btnClass: {
			type: String,
			default: "mx-0 mt-lg-5",
		},
		cardStyles: {
			type: String,
			default: "ma-2 d-flex align-start",
		},
		dataCy: {
			type: String,
			default: "",
		},
	},
	components: {},
	data: () => ({
		columnDefs: null,
		rowData: null,
		enableSorting: true,
		enableFilter: false,
		rowDragManaged: false,
		rowSelection: "multiple",
		domLayout: "autoHeight",
		width: "100%",
		maxHeight: "600px",
		rowHeight: 60,
		noRowsOverlayComponent: null,

		gridApi: {},
		columnApi: {},
		defaultColDef: {
			flex: 1,
			editable: false,
			sortable: true,
			resizable: true,
			filter: false,
			minWidth: 130,
			draggable: true,
			rowDrag: false,
		},
	}),
	created() {},
	async mounted() {},
	computed: {},
	methods: {
		handleClick() {
			this.$emit("click");
		},
	},
	watch: {},
});
